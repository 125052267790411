import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home2 from './pages/home2';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1200
    }
  }
});
function App() {
  return (

    <ThemeProvider theme={theme} >  <BrowserRouter>
      <Routes>


        <Route path="/home" element={<Home2 />} />
        <Route path="/" element={<Home2 />} />


      </Routes>
    </BrowserRouter></ThemeProvider>

  );
}

export default App;
