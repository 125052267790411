import {
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import NorthEastIcon from '@mui/icons-material/NorthEast';
import ReactDOM from "react-dom";
import DrawerAppBar from "../components/AppBar";
import background from "./bg5.jpg";
import background2 from "./bg4.jpg";
import Contact from "../components/contact";
import Footer from "../components/footer";
import { CardMedia } from "@material-ui/core";
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 450,
            md: 600,
            lg: 900,
            xl: 1200,
        },
    },
});

const styles = (theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            padding: "1rem",
        },
        backgroundImage: `url(${background})`,
        margin: "0rem",
        backgroundSize: "cover",
        backgroundPositiony: "bottom",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        color: "white",
        height: "100vh",
        padding: "13rem",
        paddingTop: "10rem",
    },
    faaliyet: {

        fontSize: "1rem",
        color: "#164558",



    },

    card: {
        borderRadius: "60%",
        padding: "2rem",
        opacity: "0.6",
    },
    button: {
        borderRadius: "60%",
        padding: "1rem",
        backgroundColor: "white !important",
        color: "#078848 !important",
        marginTop: "2rem !important",
        textTransform: "none !important",
    },
    cardAbout: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {

            padding: "2rem",

            minHeight: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            minHeight: "40vh",
            borderRadius: "5%",
            padding: "2rem",
            backgroundColor: "white",
            color: "black",

        },
    },
    cardFeatures: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {

            padding: "2rem",
            maxHeight: "53vh",
            minHeight: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            minHeight: "20vh",
            borderRadius: "5%",
            padding: "0.3rem",
            backgroundColor: "white",
            color: "black",
            maxHeight: "30vh",
            minHeight: "30vh",

        },
    },
    cardTech: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        [theme.breakpoints.down("sm")]: {

            padding: "2rem",
            maxHeight: "53vh",
            minHeight: "100%",
        },
        [theme.breakpoints.up("sm")]: {

            borderRadius: "5%",
            padding: "2rem !important",
            backgroundColor: "white",
            color: "black",
            maxHeight: "50vh",
            minHeight: "50vh",

        },
    },
    about: {
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            padding: "3rem",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "5rem",
            paddingTop: "5rem",
        },
        backgroundImage: `url(${background})`,

    },
    feature: {
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            padding: "3rem",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "5rem",
            paddingTop: "5rem",
        },
        backgroundImage: `url(${background2})`,
        backgroundSize: "cover",

    },
    tech: {
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            padding: "5rem",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "5rem !important",
            paddingTop: "5rem",
        },
        backgroundColor: "#efefef",

    },
    text: {
        fontSize: "1rem",
        color: "#164558",
    },
    img: {
        [theme.breakpoints.down("sm")]: {
            height: "60vw",

        },
        [theme.breakpoints.up("sm")]: {
            width: "50%",
            height: "50%",
        },
    },
    text2: {
        fontSize: "1rem !important",
        color: "#164558",
        fontWeight: "bold !important",
    },
    about1: {
        [theme.breakpoints.down("sm")]: {
            padding: "3rem",
            height: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "5rem",
            paddingTop: "5rem",
        },

        backgroundColor: "white",
    },
    contact: {
        [theme.breakpoints.down("sm")]: {
            padding: "3rem",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "5rem",
            paddingTop: "5rem",
        },

        backgroundColor: "white",
    },
    buttonTech: {
        borderRadius: "1.5rem !important",
        padding: "0.5rem !important",
        backgroundColor: '#078848 !important',
        color: "white !important",
        marginTop: "2rem !important",
        textTransform: "none !important",
        width: "50% !important",
    },
});

const faaliyetler = [
    "Renove ODS Otomatik Dümenleme Sistemi sürücünün traktörü ile daha önceden belirlenen rotada 2 cm hassasiyet ile otomatik olarak hareket edebilmesini sağlayan dümenleme sistemidir. Traktör sürücüye gerek duymadan uydudan almış olduğu konum bilgileri ile istenilen güzergahta ilerleyebilmektedir. Sıra ile ekim yapılan bitki tiplerinde daha fazla hat çekilirken yakıt, tohum, ilaç ve gübre maliyetleri azalır.",
    "Çalışma ortamından bağımsız olarak gece ya da gündüz çalışabilen renove Otomatik Dümenleme sistemi, traktörün her hava ve çevre koşulunda 2 cm hassasiyetle çalışmasını sağlar.",
    "Renove Otomatik Dümenleme Sistemi ile değişik çalışma ortamlarında tam kapasite çalışabilirsiniz. Her marka ve model Traktörünüzü , akıllı tarım aracına dönüştürebilirsiniz.",
    "Akıllı Tarım kapsamında,tohumlama, sırt, gübreleme, hasat,ilaçlama,sulama vb. Alanlarda hassas olarak görev yapabilmektedir.",
    "Maliyeti azaltmak ve verimliliği arttırmak için renove Otomatik Dümenleme Sİstemi artık Türkiye’de Çiftçilerimizin kullanımına sunulmuştur."

];
const features = [
    { id: 1, title: 'Hassas Ekim', image: 'Picture1.png' },
    { id: 2, title: 'Hassas Çapalama', image: 'Picture2.png' },
    { id: 3, title: 'Hassas Sulama', image: 'Picture3.png' },
    { id: 3, title: 'Uygun Fiyat', image: 'Picture6.png' },
    { id: 4, title: '4 farklı uydu sistemi', image: 'Picture7.jpg' },
    { id: 5, title: 'Online-Offline Özelliği', image: 'Picture9.png' },
    { id: 6, title: 'Uygulama ve Güncellemeler Ücretsiz', image: 'Picture10.png' },
    { id: 7, title: 'Teknik Servis', image: 'Picture11.png' },
    { id: 8, title: 'Yerli ve Milli Anakart', image: 'Picture12.jpg' },
    { id: 9, title: 'Ücretsiz Mobil Uygulama', image: 'Picture13.jpg' },
    // diğer kartlar
];
function CardList({ cards, classes }) {

    return (
        <Grid container spacing={2}>
            {cards.map((card) => (
                <Grid key={card.id} item xs={12} sm={6} md={3}>
                    <Card className={classes.cardFeatures} >   <CardContent style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "12vh",
                    }} >
                        <Typography style={{
                            fontSize: "1rem",
                            color: "#078848 ",
                            fontWeight: "bold",
                            marginTop: "1rem",
                        }} gutterBottom variant="h5" component="div">
                            {card.title}
                        </Typography>
                    </CardContent>
                        <CardMedia style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",

                            width: "50%",
                            height: "50%",

                        }} component="img" image={card.image} alt={card.title} />

                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}
function Home({ classes }) {
    return (
        <div>
            <DrawerAppBar />
            <Grid className={classes.root} container alignItems={"center"}>
                <Grid item xs={12} >
                    <div  >
                        <Card style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",

                        }} className={classes.card} > <p
                            style={{
                                fontSize: "2rem",
                                fontWeight: "bold",
                                textAlign: "center",
                                justifyContent: "center",
                                color: "#078848",

                            }}
                        >
                                Tarımın Geleceği Teknolojide Saklı
                            </p>
                        </Card>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",

                        }}> </div>


                    </div>
                </Grid>
            </Grid>


            <Grid
                id="faaliyet"
                className={classes.about}
                spacing={2}
                container
                alignItems={"center"}
            >
                <Grid container alignItems={"center"}>
                    {" "}
                    <Typography
                        sx={{ fontSize: 26, color: "white", fontWeight: "bold" }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Faaliyet Alanımız
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Card className={classes.cardAbout}>
                        <CardContent className={classes.cardAbout}>
                            <img
                                src="field.png"
                                alt="elektrik"
                                style={{ width: "70px", height: "70px" }}
                            />
                            <Typography
                                sx={{ fontSize: 20, color: "#078848", fontWeight: "bold" }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Otomatik Dümenleme Sistemi
                            </Typography>


                            <ul>
                                {faaliyetler.map(faaliyet => (
                                    <li className={classes.faaliyet} >{faaliyet}</li>
                                ))}
                            </ul>

                        </CardContent>
                    </Card>
                </Grid>

            </Grid>

            <Grid
                id="teknik"
                className={classes.feature}
                spacing={2}
                container
                alignItems={"center"}
            >
                <Grid container alignItems={"center"}>
                    {" "}
                    <Typography
                        sx={{ fontSize: 26, color: "white", fontWeight: "bold", marginBottom: "2rem" }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Teknik Özellikler ve Avantajlar                   </Typography>
                </Grid>

                <Grid item xs={12} container spacing={2}>
                    <CardList cards={features} classes={classes} />
                </Grid>


            </Grid>
            <div className={classes.tech} style={{
                backgroundColor: "#efefef",
            }} >  <Grid
                id="urun"

                spacing={2}
                container
                alignItems={"center"}
                style={{ padding: "0", margin: "0 auto" }}
            >
                    <Grid style={{
                        width: '100vw',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",



                    }} container alignItems={"center"}>
                        {" "}
                        <Typography
                            sx={{ fontSize: 26, color: "#078848", fontWeight: "bold" }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Teknolojiler ve Ürünler
                        </Typography>
                    </Grid>
                    <Grid style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }} item xs={12} md={12}>
                        <img src="sema.PNG" alt="elektrik" className={classes.img} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Card className={classes.cardTech}>
                            <CardContent className={classes.cardTech}>
                                <img
                                    src="Picture17.png"
                                    alt="elektrik"
                                    style={{ width: "10rem", }}
                                />


                                <Typography
                                    sx={{ fontSize: 16, color: "#078848", fontWeight: "bold", marginTop: '1rem' }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    Motorlu Direksiyon
                                </Typography>


                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Card className={classes.cardTech}>
                            <CardContent className={classes.cardTech}>
                                <img
                                    src="Picture16.png"
                                    alt="elektrik"
                                    style={{ width: "10rem", }}
                                />

                                <Typography
                                    sx={{ fontSize: 16, color: "#078848", fontWeight: "bold", marginTop: '1rem' }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    Motorize Edilmiş Direksiyon
                                </Typography>


                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Card className={classes.cardTech}>
                            <CardContent className={classes.cardTech}>
                                <img
                                    src="Picture19.png"
                                    alt="elektrik"
                                    style={{ width: "10rem", }}
                                />
                                <Typography
                                    sx={{ fontSize: 16, color: "#078848", fontWeight: "bold", marginTop: '1rem' }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    GNSS 4G Versiyonu (Tusaga Entegrasyon)
                                </Typography>


                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Card className={classes.cardTech}>
                            <CardContent className={classes.cardTech}>
                                <img
                                    src="Picture14.png"
                                    alt="elektrik"
                                    style={{ height: "8rem" }}
                                />
                                <Typography
                                    sx={{ fontSize: 16, color: "#078848", fontWeight: "bold", marginTop: '1rem' }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    GNSS BASE +GNSS ROVER Versiyonu
                                </Typography>


                            </CardContent>
                        </Card>
                    </Grid>
                </Grid></div>

            <Grid
                id="contact"
                className={classes.contact}
                spacing={2}
                container
                alignItems={"center"}
            >
                <Grid container>
                    {" "}
                    <Typography
                        sx={{
                            fontSize: 26,
                            color: "#078848",
                            fontWeight: "bold",
                            marginBottom: "4rem",
                        }}
                        color="text.secondary"
                        gutterBottom
                    >
                        İletişim
                    </Typography>
                </Grid>
                <Grid container alignItems={'center'} >

                    <Grid item xs={12} md={6}>
                        {" "}
                        <img style={{
                            width: "90%",
                            borderRadius: "20px",
                            objectFit: "cover",
                            objectPosition: "center",

                        }} src="bg6.jpg" alt="map" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Contact />
                    </Grid>
                </Grid>
            </Grid>
            <Footer />


        </div>
    );
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Home);
