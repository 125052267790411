import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
const theme = createTheme({
    breakpoints: {
        // Define custom breakpoint values.
        // These will apply to Material-UI components that use responsive
        // breakpoints, such as `Grid` and `Hidden`. You can also use the
        // theme breakpoint functions `up`, `down`, and `between` to create
        // media queries for these breakpoints
        values: {
            xs: 0,
            sm: 450,
            md: 600,
            lg: 900,
            xl: 1200
        }
    }
});

const styles = theme => ({
    style: {
        [theme.breakpoints.down("sm")]: {
            padding: "1rem",
        },
        backgroundColor: "#078848 !important",
        color: "white",
        borderTop: "1px solid #E7E7E7",
        textAlign: "center",
        paddingTop: "2rem",
        overflowX: "hidden",
        left: "0",
        bottom: "0",

    },

});

function Footer({ classes }) {
    return (
        <div>
            <div className={classes.style}>
                <Grid container>
                    <Grid item xs={12} sm={5}>
                        <img src="email-sign2.jpg" />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Grid container style={{ marginBottom: "10px" }}>
                            {" "}
                            <a href="#faaliyet"
                                style={{
                                    textTransform: "none",
                                    textDecoration: "none",
                                    color: "white",
                                }}
                            >
                                Faaliyet Alanlarımız
                            </a>
                        </Grid>
                        <Grid container style={{ marginBottom: "10px" }}>
                            {" "}
                            <a href="#urun"
                                style={{
                                    textTransform: "none",
                                    textDecoration: "none",
                                    color: "white",
                                }}
                            >
                                Ürünlerimiz
                            </a>{" "}
                        </Grid>
                        <Grid container style={{ marginBottom: "10px" }}>
                            {" "}
                            <a href="#contact"
                                style={{
                                    textTransform: "none",
                                    textDecoration: "none",
                                    color: "white",
                                }}
                            >
                                İletişim
                            </a>{" "}
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                        <Grid container>
                            {" "}
                            <b>Sosyal Medya</b>
                        </Grid>

                        <Grid style={{ marginTop: "2rem" }} container spacing={2}>
                            <Grid item>
                                <img src="images/facebook.png" />
                            </Grid>
                            <Grid item>
                                <img src="images/twitter.png" />
                            </Grid>
                            <Grid item>
                                <img src="images/insta.png" />
                            </Grid>
                            <Grid item>
                                <img src="images/linkedin.png" />
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </div>
        </div>
    );
}

export default withStyles(styles)(Footer);
